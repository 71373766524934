import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CalendarColor } from '@app/enum/calendar-color.enum';
import { SprintSettings } from '@app/model/sprint.model';
import { maxDuration, minDuration } from '@validator/duration-validator';
import { Project } from 'src/app/model/project.model';
import {
    EmailFormatValidator,
    RemainingHolidayValidator,
    HolidaysRangeValidator,
    NotDefaultValueValidator,
    RequiredBasedOnFormControl,
    RequiredIf,
    SmallerThanOrEqual,
    hasLengthValidator,
    patternValidator,
} from 'src/helper/validators';
import { Coworker } from '../model/coworker.model';
import { Folder } from '../model/folder.model';
import { SharedDocument } from '../model/shared-document.model';
import { WorklogFormData } from '../model/worklog-formdata.model';
import { Tag } from '@app/model/tag.model';
import WorklogData from '@app/model/worklog-data';
import { FteValue } from '@app/enum/fte-value.enum';

@Injectable({
    providedIn: 'root',
})
export class FormBuilderService {
    constructor(private formBuilder: UntypedFormBuilder) {}

    createProjectForm(project: Project, tags: Tag[]): UntypedFormGroup {
        return this.formBuilder.group({
            // clientId: [project.clientId, [Validators.required]],
            name: [project.name, [hasLengthValidator()]],
            fte: [project.fte, [Validators.pattern(`^[\\d]+(\\.[\\d]{1,2})?$`)]],
            // sprintWeeks: [project.sprintWeeks, [Validators.pattern('^[\\d]+$')]],
            // sprintFirstDate: [project.sprintFirstDate, [Validators.required]],
            // paymentPeriod: [project.paymentPeriod, [Validators.required]],
            // accountingFirstDate: [project.accountingFirstDate, [Validators.required]],
            activated: [project.activated],
            paid: [project.paid],
            paidQA: [project.paidQA],
            paidPm: [project.paidPm],
            paidDesign: [project.paidDesign],
            // currency: [project.currency, [Validators.required]],
            // discount: [project.discount, [Validators.pattern('^((0)|(100)|[123456789]{1}[0123456789]{0,1}){0,1}$')]],
            // language: [project.language, [Validators.required]],
            // hourlyRate: [project.hourlyRate, [Validators.pattern('^[\\d]+$')]]
            tags: [tags],
            type: [project.type, [Validators.required, NotDefaultValueValidator()]],
            deadline: [project.deadline],
        });
    }

    checkSmallerThanOrEqual(coworker: Coworker) {
        if (coworker.totalHolidays !== null && coworker.remainingHolidays !== null) {
            SmallerThanOrEqual('totalHolidays');
        }
    }

    createCoworkerForm(coworker: Coworker): UntypedFormGroup {
        const remainingHolidaysValidators = [Validators.required, RemainingHolidayValidator('totalHolidays')];
        const totalHolidaysValidators = [Validators.required, HolidaysRangeValidator('total')];

        return this.formBuilder.group({
            name: [coworker.name, [Validators.required, patternValidator(/^[^0-9]*$/, 'invalidName')]],
            email: [coworker.email, [Validators.required, EmailFormatValidator()]],
            active: [coworker.activated],
            fte: [coworker.fte, [Validators.pattern('(^0(\\.[\\d]{1,2})?)$|(^1(\\.(0){1,2})?)$')]],
            role: [coworker.role, [NotDefaultValueValidator()]],
            totalHolidays: [coworker.totalHolidays, totalHolidaysValidators],
            remainingHolidays: [coworker.remainingHolidays, remainingHolidaysValidators],
            intern: [coworker.intern],
            calendarColor: [coworker.calendarColor ?? CalendarColor.SUN_YELLOW],
        });
    }

    createFolderForm(folder: Folder): UntypedFormGroup {
        return this.formBuilder.group({
            id: [folder.id],
            name: [folder.name, [hasLengthValidator()]],
            folderPermissions: [folder.folderPermissions],
        });
    }

    createSharedDocumentForm(document: SharedDocument): UntypedFormGroup {
        return this.formBuilder.group({
            id: [document.id],
            folderId: [document.folderId, [Validators.required, Validators.min(1)]],
            name: [document.name, [Validators.required, Validators.min(2), Validators.max(40)]],
            oneTimeOpenPermission: [document.oneTimeOpenPermission],
        });
    }

    createFteForm(
        fte: number,
        isReviewer: boolean,
        maxAssignableFte: number,
        isProjectRemovalNeeded: boolean,
    ): UntypedFormGroup {
        return this.formBuilder.group(
            {
                fte: [
                    fte,
                    [
                        RequiredIf('isReviewer', false),
                        Validators.min(0),
                        Validators.max(maxAssignableFte),
                        Validators.pattern('(^0(\\.[\\d]{1,2})?)$|(^1(\\.(0){1,2})?)$'),
                    ],
                ],
                isReviewer: [isReviewer, [Validators.required]],
                removeFrom: [null],
                isProjectRemovalNeeded: [isProjectRemovalNeeded],
            },
            {
                validator: RequiredBasedOnFormControl('fte', 'removeFrom', FteValue.HALF, 'isProjectRemovalNeeded'),
            },
        );
    }

    createLanguageForm(language: string): UntypedFormGroup {
        return this.formBuilder.group({
            language: [language],
        });
    }

    createAppearanceForm(appearance: string): UntypedFormGroup {
        return this.formBuilder.group({
            appearance: [appearance],
        });
    }

    createWorklogArrayForm(worklog: WorklogFormData, pmView: boolean): UntypedFormGroup {
        return this.formBuilder.group({
            items: this.formBuilder.array([this.createWorklogItem(worklog, pmView)]),
        });
    }

    createWorklogPopupFormArray() {
        return this.formBuilder.group({
            inputs: this.formBuilder.array([]),
        });
    }

    createSprintForm(sprint?: SprintSettings): UntypedFormGroup {
        return this.formBuilder.group({
            weeks: [
                sprint?.weeks,
                [Validators.required, Validators.min(1), Validators.max(10), Validators.pattern('^\\d+$')],
            ],
            start: [sprint?.start, [Validators.required]],
        });
    }

    createWorklogPopupForm(): UntypedFormGroup {
        return this.formBuilder.group({
            worklogInputs: ['', Validators.required],
        });
    }

    createWorklogItem(worklog: WorklogFormData, pmView: boolean, lastItem: UntypedFormGroup = null) {
        if (lastItem === null) {
            const project = pmView ? [worklog.project?.id] : [worklog.project?.id, [Validators.required]];
            return this.formBuilder.group({
                duration: [worklog.duration, [Validators.required, minDuration('00:01'), maxDuration('12:00')]],
                project,
                task: [worklog.task, [hasLengthValidator()]],
                description: [worklog.description, [hasLengthValidator()]],
            });
        } else {
            const project = pmView
                ? [lastItem.get('project').value]
                : [lastItem.get('project').value, [Validators.required]];
            return this.formBuilder.group({
                duration: [
                    lastItem.get('duration').value,
                    [Validators.required, minDuration('00:01'), maxDuration('12:00')],
                ],
                project,
                task: [lastItem.get('task').value, [hasLengthValidator()]],
                description: [lastItem.get('description').value, [hasLengthValidator()]],
            });
        }
    }

    createWorklogTaskForm(worklog: WorklogData) {
        return this.formBuilder.group({
            id: [worklog.id, Validators.required],
            task: [worklog.task, Validators.required],
        });
    }
}
