import { Injectable } from '@angular/core';
import { IdentityResponse } from '../model/identity-response.model';
import { COWORKER_MENU_LABELS, COWORKER_TAB_ROLE_ACCESS_MAP } from '@app/constants/constants';
import { Coworker } from '@app/model/coworker.model';
import { AppearanceOptions } from '@app/enum/appearance-options.enum';
import { toggleMode } from '../../helper/appearanceSwitchHelper';
import { BehaviorSubject } from 'rxjs';

const TOKEN_KEY = 'auth-token';
const REFRESH_TOKEN_KEY = 'auth-refresh-token';
const USER_KEY = 'auth-user';
const LANGUAGE_KEY = 'language';

@Injectable({
    providedIn: 'root',
})
export class TokenStorageService {
    public saveLanguage(language: string) {
        localStorage.removeItem(LANGUAGE_KEY);
        localStorage.setItem(LANGUAGE_KEY, language);
    }

    public getAppearance(): string {
        const user = localStorage.getItem(USER_KEY);

        let value = AppearanceOptions.LIGHT;

        if (user) {
            value = JSON.parse(localStorage.getItem(USER_KEY)).appearanceOption;
        }

        return value;
    }

    public getLanguage(): string {
        return JSON.parse(localStorage.getItem(USER_KEY)).language;
    }

    public setAppearance(appearance: AppearanceOptions) {
        const updatedValue = JSON.parse(localStorage.getItem(USER_KEY));
        updatedValue.appearanceOption = appearance;

        localStorage.setItem(USER_KEY, JSON.stringify(updatedValue));
        toggleMode(appearance);
        this.setAppearanceChange(appearance);
    }

    public appearanceBehaviour = new BehaviorSubject<AppearanceOptions>(AppearanceOptions[this.getAppearance()]);
    appearance$ = this.appearanceBehaviour.asObservable();

    private setAppearanceChange(value: AppearanceOptions) {
        this.appearanceBehaviour.next(value);
    }

    public saveToken(token: string) {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.setItem(TOKEN_KEY, token);
    }

    public getToken(): string {
        return localStorage.getItem(TOKEN_KEY);
    }

    public saveRefreshToken(refreshToken: string) {
        localStorage.removeItem(REFRESH_TOKEN_KEY);
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    }

    public getRefreshToken(): string {
        return localStorage.getItem(REFRESH_TOKEN_KEY);
    }

    public saveUser(user: IdentityResponse) {
        localStorage.removeItem(USER_KEY);
        localStorage.setItem(USER_KEY, JSON.stringify(user));

        toggleMode(AppearanceOptions[this.getAppearance()]);
    }

    public updateUser(user: Coworker) {
        const identityResponse: IdentityResponse = JSON.parse(localStorage.getItem(USER_KEY));

        identityResponse.name = user.name;
        identityResponse.email = user.email;
        identityResponse.role = user.role;

        this.saveUser(identityResponse);
    }

    public getUserName(): string {
        return JSON.parse(localStorage.getItem(USER_KEY)).name;
    }

    hasRole(roles: string[] = []): boolean {
        return roles.includes(this.getRole());
    }

    public isAdmin(): boolean {
        return this.getRole() === 'ADMIN';
    }

    public isHR(): boolean {
        return this.getRole() === 'HR';
    }

    public isEngineer(): boolean {
        return this.getRole() === 'ENGINEER';
    }

    public isPM(): boolean {
        return this.getRole() === 'PROJECT_MANAGER';
    }

    public isQA(): boolean {
        return this.getRole() === 'QA';
    }

    public isExecutiveAssistant(): boolean {
        return this.getRole() === 'EXECUTIVE_ASSISTANT';
    }

    public isSales(): boolean {
        return this.getRole() === 'SALES';
    }

    public isMarketing(): boolean {
        return this.getRole() === 'MARKETING';
    }

    public isIt(): boolean {
        return this.getRole() === 'IT';
    }

    public isDesigner(): boolean {
        return this.getRole() === 'DESIGNER';
    }

    public isSem(): boolean {
        return this.getRole() === 'SEM';
    }

    public isIntern(): boolean {
        return JSON.parse(localStorage.getItem(USER_KEY)).intern;
    }

    public getRole(): string {
        return JSON.parse(localStorage.getItem(USER_KEY)).role;
    }

    public getUserId(): number {
        return JSON.parse(localStorage.getItem(USER_KEY)).id;
    }

    public isLoggedIn(): boolean {
        return localStorage.getItem(TOKEN_KEY) !== null;
    }

    public async removeAuthToken() {
        localStorage.removeItem(TOKEN_KEY);
    }

    public clearStorage() {
        localStorage.clear();
    }

    public getFirstAccessibleRole() {
        return COWORKER_MENU_LABELS.find((coworkerMenuLabel) =>
            this.hasRole(COWORKER_TAB_ROLE_ACCESS_MAP[coworkerMenuLabel.role]),
        ).role;
    }
}
