import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { setDefaultOptions } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { hu } from 'date-fns/locale';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { SnackbarService } from '@app/service/snackbar.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private readonly en = 'en';
    private readonly availableLanguages = [this.en, 'hu'];
    private readonly defaultLanguage = this.availableLanguages[0];
    private readonly userKey = 'auth-user';
    private languageChangedSubject: Subject<string> = new Subject<string>();

    languageChanged$ = this.languageChangedSubject.asObservable();

    constructor(
        private translate: TranslateService,
        private httpClient: HttpClient,
        private snackbarService: SnackbarService,
    ) {}

    updateUserLanguage(language: string) {
        if (!this.availableLanguages.includes(language)) {
            this.snackbarService.openSnackbarWithMessage('error.UNEXPECTED_ERROR_OCCURRED');
            return;
        }

        this.sendLanguageSetRequest(language).subscribe({
            next: () => {
                this.updateUserInStorge(language);
                this.setTranslationLanguage(language);
                this.languageChangedSubject.next(language);
                this.snackbarService.openSnackbarWithMessage('settings.update-properties');
            },
            error: (err) => {
                throw new Error(err);
            },
        });
    }

    sendLanguageSetRequest(language: string) {
        const formData: FormData = new FormData();
        formData.append('language', language);

        return this.httpClient.patch(`${environment.serverUrl}/user/language`, formData);
    }

    setTranslationLanguage(language: string) {
        this.translate.use(language);
        moment.locale(language);
        setDefaultOptions({ locale: language.startsWith(this.en) ? enGB : hu });
    }

    getLanguage(): string {
        return this.parseUserFromStorage()?.language ?? this.defaultLanguage;
    }

    initAppLanguage() {
        this.translate.addLangs(this.availableLanguages);
        this.translate.setDefaultLang(this.defaultLanguage);

        const language = this.getLanguage();
        this.setTranslationLanguage(
            language && this.availableLanguages.includes(language) ? language : this.defaultLanguage,
        );
    }

    updateUserInStorge(language: string) {
        const user = this.parseUserFromStorage();
        user.language = language;
        localStorage.removeItem(this.userKey);
        localStorage.setItem(this.userKey, JSON.stringify(user));
    }

    parseUserFromStorage() {
        return JSON.parse(localStorage.getItem(this.userKey));
    }
}
