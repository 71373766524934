import { duration } from 'moment';

const HOUR_INDEX: number = 1;
const MIN_INDEX: number = 2;
const ONE_HOUR_IN_MINUTES = 60;

export const DURATION_FORMAT_REGEX = /^(\d{1,2}):([0-5]|[0-5][0-9])$/;

export function convertDurationStringToMin(value: string, regex: RegExp = DURATION_FORMAT_REGEX): number {
    let matches = value.match(regex);
    return matches && matches.length === 3
        ? Number(matches[HOUR_INDEX]) * ONE_HOUR_IN_MINUTES + Number(matches[MIN_INDEX])
        : undefined;
}

export const convertMinutesToDurationString = (minutes: number): string => {
    const positiveMinutes = Math.abs(minutes);
    const h = Math.floor(positiveMinutes / ONE_HOUR_IN_MINUTES);
    const m = (positiveMinutes - h * ONE_HOUR_IN_MINUTES).toString();
    return `${(minutes < 0 ? h * -1 : h).toString().padStart(2, '0')}:${m.padStart(2, '0')}`;
};

export function convertISO8601DurationToMinutes(value: string): number {
    return duration(value).asMinutes();
}
