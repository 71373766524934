import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpService } from './http.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@app/../environments/environment';
import { TokenStorageService } from './token-storage.service';
import { AppearanceOptions } from '@app/enum/appearance-options.enum';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private imageSource = new BehaviorSubject(`${environment.serverUrl}/user/profile/${this.tokenStorage.getUserId()}`);
    currentImage = this.imageSource.asObservable();
    constructor(
        // tslint:disable-next-line:deprecation
        private httpService: HttpService,
        private tokenStorage: TokenStorageService,
    ) {}

    updateAppearance(appearance: AppearanceOptions) {
        return this.httpService.patch('/user/appearance', { appearance: appearance }).subscribe(() => {
            this.tokenStorage.setAppearance(appearance);
        });
    }

    getProfilePic(id: any) {
        return this.httpService.getFile('/user/profile/', id);
    }

    getProfilePicURL(id: any) {
        return this.getProfilePic(id).pipe(map((data) => URL.createObjectURL(data)));
    }

    changeProfilePic(content: any) {
        this.imageSource.next(content);
    }
}
